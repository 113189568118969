import AboutSection from "../components/about";
import BadBitches from "../components/badBitches";
import Facilities from "../components/facilities";
import Footer from "../components/footer";
import Hero from "../components/hero";
import SideBar from "../components/sidebar";

const Homepage = () => {
  return (
    <>
      <SideBar />
      <div className="main-content">
        <Hero />
        <AboutSection />
        <Facilities />
        <BadBitches />
        <Footer />
      </div>
    </>
  );
};

export default Homepage;
