import maleLifter from "../assets/images/male-lifter.png";
const AboutSection = () => {
  return (
    <section id="aboutUs">
      <div className="about-us__img-container">
        <img src={maleLifter} alt="Spartan lifter" className="about-us__img" />
      </div>
      <div className="about-us__content inner-content">
        <h3 className="heading">With your shield or on it</h3>
        <p>Welcome to Stockport Spartans weightlifting club!</p>
        <p>
          A BWL-associated club, specializing in Olympic weightlifting as well
          as having strong associations with powerlifting. Since 2020 we have
          been at home in Mygym Hazel Grove, south Stockport. Our club has grown
          dramatically since our move, we are proud to have athletes from all
          walks of life and ranges of ability, from hobby lifters to national
          medalists. Stockport Spartans has invested heavily to make sure the
          equipment provided is the best the sport has to offer to ensure the
          highest possible standards of training are achieved. Coupled with
          three British weightlifting qualified coaches we are fully suited to
          assist at all levels of fitness and skill.
        </p>

        <p>
          Our aim is to grow the sport of Olympic weightlifting within Stockport
          and the Northwest by making it accessible to all. Our passion for the
          sport is felt by all the members.
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
