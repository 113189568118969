import { Link } from "react-router-dom";

import spartanShield from "../assets/branding/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found__content">
        <img
          className="not-found__logo"
          src={spartanShield}
          alt="Stockport Spartans"
        />
        <h1 className="not-found__title">404</h1>
        <h2 className="not-found__subtitle">
          Oops the link you're looking for doesn't exist
        </h2>
        <Link className="link" to="/">
          <FontAwesomeIcon icon={faArrowLeft} /> Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
