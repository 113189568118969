const Facilities = () => {
  return (
    <section id="facilities">
      <div className="facilities__img"></div>
      <div className="facilities__content inner-content">
        <h3 className="heading heading--centre">Our Facilities</h3>
        <p>Spartans are privileged to train out of MyGym in Hazel Grove.</p>
        <a
          href="https://www.mygymofficial.co.uk/contact"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          Find us
        </a>
      </div>
    </section>
  );
};

export default Facilities;
