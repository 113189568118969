import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import spartanShield from "../assets/branding/logo.png";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const MembersArea = () => {
  return (
    <div className="members-login">
      <div className="members-login__content">
        <img
          className="members-login__logo"
          src={spartanShield}
          alt="Stockport Spartans"
        />
        <h1 className="members-login__title">Greatness takes time</h1>
        <h2 className="members-login__subtitle">
          Check back soon, if you're a member...
        </h2>
        <Link className="link" to="/">
          <FontAwesomeIcon icon={faArrowLeft} /> Go Home
        </Link>
      </div>
    </div>
  );
};

export default MembersArea;
