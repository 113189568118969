import femaleLifter from "../assets/images/female-lifter.png";

const BadBitches = () => {
  return (
    <section id="badBitches">
      <div className="bb__img-container">
        <img src={femaleLifter} alt="Spartan lifter" className="bb__img" />
      </div>
      <div className="bb__content inner-content">
        <h3 className="heading">Join the bad bitches club</h3>
        <p>
          It's hard to believe women's Olympic weightlifting has only been in
          the Olympics since the year 2000. Since the games in Sydney, female
          membership in British weightlifting has been on a steady increase.
          Beyond the physical benefits of increased strength and muscle tone,
          the sport fosters a profound sense of self-confidence and
          accomplishment, which for some is more rewarding than the increased
          strength. Olympic weightlifting challenges the traditional gender
          norms, showcasing that women can excel in what may still be considered
          a male-dominated environment.
        </p>
        <a
          href="https://stockport-spartans.teemill.com/"
          className="button"
          target="_blank"
          rel="noreferrer"
        >
          Get some merch
        </a>
      </div>
    </section>
  );
};

export default BadBitches;
