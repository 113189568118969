import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/home";
import NotFound from "./pages/notFound";
import MembersArea from "./pages/members";

function App() {
  const navCheck = (e: any) => {
    if (window.innerWidth < 992) {
      let html = document.querySelector("html");

      if (
        !e.target.matches(".sidebar-toggle, .sidebar-toggle *") &&
        !e.target.matches(".sidebar, .sidebar *")
      ) {
        html?.classList.remove("sidebar--open");
      }
    }
  };

  return (
    <div className="site" onClick={navCheck}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/members" element={<MembersArea />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
