const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__content">
        <h1 className="hero__title">Stockport Spartans Weightlifting Club</h1>
        <h4 className="hero__subtitle">
          Passion <span>|</span> Courage <span>|</span> Unity
        </h4>
      </div>
    </div>
  );
};

export default Hero;
